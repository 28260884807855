<template>
  <v-app id="ranes" class="ranes">
    <DevFAB />

    <fade-in-background v-if="showBackground" />

    <TheHeader class="header" v-if="showHeaderAndNav"> </TheHeader>

    <TheMainNav v-if="showHeaderAndNav" />

    <v-main id="main" :class="!$vuetify.breakpoint.mobile && showHeaderAndNav ? 'head-pad' : ''">
      <transition :name="transition" :mode="transitionMode">
        <router-view />
      </transition>
    </v-main>

    <TheFooter v-if="$vuetify.breakpoint.mobile && showHeaderAndNav"></TheFooter>

    <AppSnackBar></AppSnackBar>
  </v-app>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheMainNav from "@/components/TheMainNav";
import TheFooter from "@/components/TheFooter";
import AppSnackBar from "@/components/AppSnackBar";
import FadeInBackground from "@/components/FadeInBackground";
import DevFAB from "@/components/DevFAB";

export default {
  name: "Index",

  components: {
    TheHeader,
    TheFooter,
    TheMainNav,
    AppSnackBar,
    FadeInBackground,
    DevFAB,
  },

  metaInfo: {
    title: "Home",
  },

  computed: {
    showBackground() {
      if (!this.$route) {
        return true;
      }

      if (this.$route.meta?.noBackground) {
        return false;
      }

      if (this.$route.meta?.public) {
        return true;
      }

      return false;
    },

    showHeaderAndNav() {
      if (!this.$route) {
        return false;
      }

      if (this.$route.meta?.public) {
        return false;
      }

      return true;
    },
  },

  data() {
    return {
      transition: null,
      transitionMode: null,
    };
  },

  watch: {
    $route(to, from) {
      if (from.name == "login" && to.name == "forgotpassword") {
        this.transition = "scroll-down";
        this.transitionMode = "out-in";
      } else if (to.name == "login" && from.name == "forgotpassword") {
        this.transition = "scroll-up";
        this.transitionMode = "out-in";
      } else if (from.name == "login" || to.name == "login") {
        this.transition = "fade";
        this.transitionMode = "out-in";
      } else {
        this.transition = null;
        this.transitionMode = null;
      }
    },
  },
};
</script>
<style web lang="scss">
@import "@/scss/app";
</style>
<style lang="scss">
.ranes {
  .head-pad {
    padding-top: 48px !important;
  }
  .online-snip {
    position: fixed;
    bottom: 1px;
    right: 5px;
    z-index: 999;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fastfade-enter-active,
  .fastfade-leave-active {
    transition: opacity 0.1s linear;
  }
  .fastfade-enter,
  .fastfade-leave-active {
    opacity: 0;
  }
  .scroll-down-enter-active,
  .scroll-down-leave-active,
  .scroll-up-enter-active,
  .scroll-up-leave-active {
    transition-duration: 0.5s;
    transition-property: height, width, opacity, transform;
    transition-timing-function: ease;
    overflow: hidden;
  }

  .scroll-down-enter,
  .scroll-up-leave-active {
    opacity: 0;
    transform: translate(0, 100px);
  }
  .scroll-down-leave-active,
  .scroll-up-enter {
    opacity: 0;
    transform: translate(0, -100px);
  }
}
</style>
