<template>
  <v-snackbar
    class="snack-wrap"
    :class="
      $vuetify.breakpoint.mobile && $platform == 'web'
        ? 'snack-mobile-web'
        : $platform !== 'web'
        ? 'snack-mobile-device'
        : ''
    "
    v-model="show"
    :color="color"
    :timeout="timeout"
    :type="type"
  >
    <v-icon v-if="color == 'success'" class="pr-2" dark style="margin-top: -2px">mdi-check-circle</v-icon>
    <v-icon v-else-if="color == 'warning'" class="pr-2" dark style="margin-top: -2px">mdi-alert-circle</v-icon>
    <v-icon v-else-if="color == 'danger'" class="pr-2" dark style="margin-top: -2px">mdi-alert-octagon</v-icon>
    <span style="font-size: 15px">{{ message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn v-if="type == 'appRefresh'" text color="#00f500" v-bind="attrs" @click.stop="refreshApp"> Refresh </v-btn>
      <v-btn icon v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import update from "@/mixins/update";
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
      timeout: 99999,
      type: "",
    };
  },
  mixins: [update],
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/showMessage") {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.type = state.snackbar.type;
        this.show = true;
      }
    });
  },
};
</script>
<style lang="scss">
.snack-mobile-web {
  bottom: 60px;
}
.snack-mobile-device {
  bottom: 90px;
}
</style>
