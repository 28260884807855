<template>
  <v-btn
    v-if="showFAB"
    elevation="24"
    fab
    fixed
    bottom
    right
    color="warning"
    style="z-index: 1000"
    class="mb-4 mr-4"
    @click="exportDiagnostics()"
  >
    <v-icon>mdi-progress-wrench</v-icon>
  </v-btn>
</template>
<script>
import { mapGetters } from "vuex";
import diagnostics from "@/diagnostics";

export default {
  name: "DevFAB",

  computed: {
    ...mapGetters("settings", ["settings"]),
  },

  data() {
    return {
      showFAB: false,
    };
  },

  methods: {
    exportDiagnostics() {
      diagnostics.exportAsync();
    },
  },

  created() {
    this.showFAB = this.settings.showDevFAB;
    this.unwatch = this.$store.watch(
      (state) => {
        return state.settings.showDevFAB;
      },
      (newValue) => {
        this.showFAB = newValue;
      },
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
};
</script>
