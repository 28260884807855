<template>
  <div class="fade-in-background-wrapper">
    <transition name="fade">
      <img class="fade-in-background-image" :src="bg" v-on:load="onLoaded" v-show="loaded" @error="error" />&nbsp;
    </transition>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loaded: false,
    };
  },
  computed: {
    bg() {
      const min = 1;
      const max = 10;
      let rand = this.getRandomIntInclusive(min, max);
      return "https://clockworksafety-public.s3.us-west-2.amazonaws.com/prod/app-bg-image/bg-" + rand + ".jpg";
    },
  },
  methods: {
    error() {
      return;
    },
    onLoaded() {
      this.loaded = true;
    },
    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
};
</script>
<style>
.fade-in-background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
}
.fade-in-background-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: grayscale(0.9);
}
.fade-enter-active {
  transition: opacity 0.3s linear;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter {
  opacity: 0;
}
</style>
